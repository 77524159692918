import React from 'react';
import Layout from '../layouts/Layout';
import PrintView from '../components/PrintView';

export const PrintVerificationTemplate = context => {
  const {
    pageContext: {
      lang,
      slug
    }
  } = context;

  return (
    <Layout title="Verify Credentials" lang={lang} slug={slug}>
      <PrintView lang={lang}/>
    </Layout>
  );
};

export default PrintVerificationTemplate;
